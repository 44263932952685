@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../public/assets/fonts/Montserrat-Bold.ttf");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main-div::-webkit-scrollbar {
  width: 6px;
}

.main-div::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 999px;
}

.container {
  width:100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 15px;
}

.dark {
  @apply bg-gray-800 text-white
}

nav.dark {
  background-color: rgb(17 24 39);
  color: #fff;
}

/*nav{*/
/*  position:sticky;*/
/*}*/

nav.dark a{
  color:#fff;
}

nav.dark .dark-bg{
  background-color: rgb(17 24 39);
}

nav.dark button{
  color:#fff;
}

nav.dark img {
  -webkit-filter: hue-rotate(180deg) invert(1);
  filter: hue-rotate(180deg) invert(1);
}

.posts-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.post {
  margin-top: 10px;
  margin-bottom:20px;
  padding: 0 15px;
  border: 1px solid darkblue;
  border-radius: 8px;
}

.post h3 {
  color: #222;
}

.post p {
  color: #777;
}

.footer {
  padding: 60px 0 30px;
}

.footer a {
  color: #555555;
  text-decoration: underline;
}

.footer a:hover {
  color: #000000;
}

.get-post-btn{
  color:white;
  background-color:darkblue;
  border:1px solid darkblue;
  padding:10px 20px;
  border-radius: 8px;
  margin: 0 auto;
  min-width:30px;
}

.title-primary {
  font-family: 'Montserrat Bold', sans-serif;
}

.bg-primary {
  background-color: rgb(255,0,0);
}
nav{
  z-index: 1;
}
.card{
  padding: 20px;
}

.truncateDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.flag {
  width: 20px;
  height: auto;
}

.edit-icon-wrapper {
  display: inline-block;
  position: relative;
}

.edit-icon-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: lightskyblue;
  opacity: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.edit-icon-wrapper:hover::after {
  background-color: rgba(173, 216, 230, 0.3);
  opacity: 1;
}

.edit-icon-wrapper:hover svg {
  fill: white;
}

.filter-buttons {
  display: inline-flex;
}

.filter-button {
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.filter-button.active {
  background-color: black; /* Add your desired active color here */
  color: #fff; /* Add your desired text color here */
}




/* Small devices (mobile phones) */
@media only screen and (max-width: 767px) {
  .claim-calendar{
    top:180px;
    right:410px;
  }
}

/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .claim-calendar{
    top:180px;
    right:380px;
  }
}

/* Large devices (desktops) */
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .claim-calendar{
    top:180px;
    right:320px;
  }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1280px) {
  .claim-calendar{
    top:180px;
    right:410px;
  }
}

.table-header{
  backgound:black!important;
  color:white!important;
}

.claim-calendar{
  background-color: white;
  color:#000000;
}

.date-filter.dark {
  background-color:#374151;
  text:white;
}



.dark .claim-calendar {
  background-color: #374151;
  color: white;
}

.dark .rdrDateRangePickerWrapper {
  background-color: #374151;
  color: white;
}

.dark .rdrDefinedRangesWrapper {
  background-color: #374151;
  color: white;
}

.dark .rdrCalendarWrapper.rdrDateRangeWrapper {
  background-color: #374151;
  color: white;
}
.month input {
  position: relative;
}


/* Default styles for the calendar icon */
.month input[type="month"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 8px;
  top: 8px;
  width: auto;
}

/* Styles for dark mode */
.dark .month input[type="month"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert the icon colors for dark mode */
}



.dark .rdrStaticRanges button.rdrStaticRange:hover {
  background-color: #374151!important;
  color: black!important;
}

.dark .rdrDayNumber span{
  color:white;
}

.dark .rdrMonthPicker select{
  color:white;
}

.dark .rdrYearPicker select{
  color:white;
}

.bg-yellow-200 {
  background-color: #f6e05e; /* Update the background color */
}

.border-yellow-500 {
  border-color: #ecc94b; /* Update the border color */
}

.text-yellow-700 {
  color: #b7791f; /* Update the text color */
}

.bg-green-200 {
  background-color: #68d391; /* Update the background color */
}

.border-green-500 {
  border-color: #48bb78; /* Update the border color */
}

.text-green-700 {
  color: #276749; /* Update the text color */
}

.parent {
  position: relative;
  width:100%;
  height:100%;
}

.overlay {
  position: fixed;  /* Fixed instead of absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;  /* Added flex */
  justify-content: center;  /* Horizontal centering */
  align-items: center;  /* Vertical centering */
  background-color: rgba(0, 0, 0, 0.5);
}


.custom-input-style {
  width: 100%;
  border: 1px solid #d1d5db;
  outline: none;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  transition: border-color 0.2s ease-in-out;
}

.custom-input-style:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}



.tooltip-content-wide {
  /* Add the desired width for the tooltip content */
  min-width: 200px; /* Adjust the width according to your needs */
  /* Add any other styling you want for the tooltip content */
  background-color: #61dafb;
  color: #fff;
  padding:4px 8px;
  border-radius: 8px;
}

.search-button{
  border-left:1px solid gainsboro;
  width:40px;
}

.border-custom{
  border:1px solid black;
}

.message {
  padding: 12px;
  border: 1px solid #eff2f7;
  margin: 8px;
  border-radius: 4px;
  margin:20px
}

.message-right {
  text-align: right;
  background-color:white;
  width: 80%;
  float:right;
}

.message-left {
  text-align: left;
  background-color:#ffffff;
  color:#000;
  width:80%;
  float:left;
}


img.supportimg{
  width:50px;
  height:50px;
  padding:10px;
}


.message-content{
  max-height: 400px;
  height:auto;
  overflow:scroll;
  margin-bottom:20px;
  padding-bottom:20px;
}

